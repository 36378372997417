var connect_factory = function( _source, _cloud_host, _ajax) {
  /**
   * Function that wraps up an ajax request (needs jquery to work)
   */
  var defaultAjaxFunction = function( _url, _data, _method, _cb ) {
    var xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function() {
      if( xhr.readyState === 4 ) {
        if( xhr.status === 200 ) {
          var response = xhr.responseText;
          try {
            response = JSON.parse( xhr.responseText );
          } catch( e ) {}
          if ( response.error ) {
            _cb( response, null );
          } else if ( response.result ) {
            _cb( null, response.result );
          } else {
            _cb( null, response);
          }
        } else {
          _cb( xhr.responseText, null );
        }
      }
    };
    xhr.open( _method, _url, true );
    xhr.setRequestHeader( 'Content-type', 'application/json' );
    if(_url.indexOf('nimbus.bitdefender.net') !== -1){
      xhr.setRequestHeader( 'x-nimbus-clientid', '281e73ed-c62f-4b15-8aa1-8474731dd498' );
    }
    xhr.send( JSON.stringify( _data ) );
  };

  var config_source = null;
  var config_host = 'https://nimbus.bitdefender.net/';
  var config_ajax = defaultAjaxFunction;
  var registrationFailedMessage = "Registration failed";

  if( typeof( _source ) === 'undefined' || _source === null ) {
    console.error( 'No connect_source set' );
    return;
  } else {
    config_source = _source;
  }
  if( typeof( _cloud_host ) === 'undefined' || _cloud_host === null ) {
    console.warn( 'No cloud host set. Will be used the default value instead!' );
  } else {
    config_host = _cloud_host;
  }
  if( typeof( _ajax ) !== 'undefined' && _ajax !== null && typeof( _ajax ) === 'function' ) {
    config_ajax = _ajax;
  }

  var nimbus = nimbus_factory( config_host, config_ajax, 1 );

  var customCallback = function ( _err, _res ) {
    if( !_err ) {
      _cb( _err, _res );
    } else {
      _cb( _err, [] );
    }
  }


  /************** CONNECT *************************/
  var connect = ( function () {

    var myCallbacks = {};
    var workingPush = false;
    /* don't use protocol relative URL */
    var prtcl = ( document.location.protocol.slice(0,5) === 'https' )? 'https': 'http';

    /**
     * Register to connect push server - push with added connect info
     **/

    var register = function ( _destination, _topic, _cb ) {
      
      if( typeof( _cb ) !== 'function' ) {
        console.error( 'connect.register callback is not a function' );
        return;
      }

      var url = config_host + 'connect/push';
      var params = {
        connect_source: config_source,
        connect_destination: _destination,
        topic: _topic,
        platform: ( prtcl === 'https' )? 'secure_web' : 'web'
      };
      var data = {
        id: 1,
        jsonrpc: '2.0',
        method: 'register',
        params: params
      };
      function generalPoll( _err, _value ) {

        var data = null;

        var payload = null;
        if (_value) {
           payload = _value.body;
        }

        try {
          data = JSON.parse( payload );
        } catch( e ) {
          console.error( 'Payload not JSON' );
          console.error( e );
          return;
        }

        var topic = null;
        if (data) {
            if (typeof(data.app_fields) === 'undefined' ||
                data.app_fields === null ||
                typeof(data.app_fields.topic) === 'undefined' ||
                data.app_fields.topic === null) {
                console.error('No topic defined');
                return;
            } else {
                topic = data.app_fields.topic;
            }
        }

        if( topic && typeof myCallbacks[ topic ] === 'function' ) {
          myCallbacks[ topic ]( null, data );
        }
      }
      myCallbacks[ _topic ] = _cb;

      config_ajax( url, data, 'POST', function ( _err, _res ) {
        if ( _err ) {
          _cb( registrationFailedMessage );
          workingPush = false;
        } else {
          if (!_res) {
            _cb( registrationFailedMessage );
            workingPush = false;
            return;
          }
          var myCoords = {
            address: _res.address,
            port: _res.port,
            push_id: _res.push_id
          };
          if ( !workingPush ) {
            workingPush = true;
            nimbus.start_polling( myCoords, generalPoll );
          }
        }
      });
    };

    return {
      register: register
    };
  })();
  /************** END OF CONNECT *************************/

  /************** NOTIFICATIONS *************************/
  var notifications = ( function() {
    var notifCb = null;
    var recallCb = null;

    var _destination = {
      'app_id': 'all',
      'device_id': 'all'
    };

    var register = function( _notif_cb, _recall_cb ) {

      if( typeof( _notif_cb ) !== 'function' ) {
        console.error( 'notifications.register push callback is not a function' );
        return;
      }
      if( typeof( _recall_cb ) !== 'function' ) {
        console.warn( 'notifications.register recall callback is not a function' );
      }

      notifCb = _notif_cb;
      recallCb = _recall_cb;

      connect.register( _destination, 'notification', function( err, data ) {
        
        if( err ) {
          notifCb( err );
          return;
        }
        
        if( typeof( data.app_fields ) !== 'undefined' && data.app_fields !== null &&
            typeof( data.app_fields.recall ) !== 'undefined' &&
            data.app_fields.recall !== null &&
            data.app_fields.recall === true &&
            typeof( data.app_fields.notification_id ) !== 'undefined' &&
            data.app_fields.notification_id !== null ) {
          recallCb( err, data.app_fields.notification_id );
          return;
        }

        if( typeof( data.user_fields ) === 'undefined' || data.user_fields === null ||
            typeof( data.user_fields.text ) === 'undefined' || data.user_fields.text === null ) {
          console.error( 'No text for notification' );
          return;
        }

        if( typeof( data.app_fields ) === 'undefined' || data.app_fields === null ) {
          console.error( 'No app_fields' );
          return;
        }

        if( typeof( data.app_fields.notification_id ) === 'undefined' ||
            data.app_fields.notification_id === null ) {
          console.error( 'No notification_id' );
          return;
        }
        if( typeof( data.app_fields.connect_source ) === 'undefined' ||
            data.app_fields.connect_source === null ) {
          console.error( 'No source' );
          return;
        }
        if( typeof( data.app_fields.connect_target ) === 'undefined' ||
            data.app_fields.connect_target === null ) {
          console.error( 'No target' );
          return;
        }
        if( typeof( data.app_fields.timestamp ) === 'undefined' ||
            data.app_fields.timestamp === null ) {
          console.error( 'No timestamp' );
          return;
        }

        var notification_id = data.app_fields.notification_id;
        var source = data.app_fields.connect_source;
        var timestamp = data.app_fields.timestamp;
        var params = null;
        if( typeof( data.app_fields.notification_params ) !== 'undefined' &&
            data.app_fields.notification_params !== null ) {
          params = data.app_fields.notification_params;
        }

        notifCb( err, data.user_fields, notification_id, source, timestamp, params );
      } );

    };

    var ack = function( _notification_id, _cb ) {
      var url = config_host + 'connect/notifications';
      var params = {
        connect_source: config_source,
        connect_destination: _destination,
        notification_id: _notification_id
      };
      var data = {
        id: 1,
        jsonrpc: '2.0',
        method: 'ack',
        params: params
      };

      config_ajax( url, data, 'POST', function ( _err, _res ) {
        _cb( _err, _res );
      } );
    };

    var get = function( _filter, _cb ) {
      var url = config_host + 'connect/notifications';
      var params = {
        connect_source: config_source,
        filter: _filter
      };
      var data = {
        id: 1,
        jsonrpc: '2.0',
        method: 'list',
        params: params
      };

      config_ajax( url, data, 'POST', customCallback );
    };

    return {
      register: register,
      ack: ack,
      get: get
    };
  } )();
  /************** END OF NOTIFICATIONS *************************/
  /************** EVENTS *************************/
  var events = ( function() {
    var eventsCb = {};

    var getTarget = function( _source, _destination ) {
      var target = '<app_id:';
      if( typeof( _destination.app_id ) !== 'undefined' && _destination.app_id !== null ) {
        target += _destination.app_id;
      } else {
        target += _source.app_id;
      }
      target += '>,<device_id:';
      if( typeof( _destination.device_id ) !== 'undefined' && _destination.device_id !== null ) {
        target += _destination.device_id;
      } else {
        target += _source.device_id;
      }
      target += '>';

      return target;
    };
    // first place event_name
    var register = function( _destination, _event_name, _cb ) {
      if( typeof( _cb ) !== 'function' ) {
        console.error( 'events.register callback is not a function' );
        return;
      }

      var target = getTarget( config_source, _destination );

      // changed
      if( typeof(_event_name) === 'object' ) {
        for(var i in _event_name) {
          if( typeof( eventsCb[ _event_name[i] ] ) === 'undefined' ) {
            eventsCb[ _event_name[i] ] = {};
          }
          eventsCb[ _event_name[i] ][ target ] = _cb;
        }
      }
      // changed

      connect.register( _destination, 'event', function( err, data ) {

        if( err && err === 'Registration failed' ) {
          return;
        }

        if( typeof( data.app_fields ) === 'undefined' || data.app_fields === null ) {
          console.error( 'No app_fields' );
          return;
        }

        if( typeof( data.app_fields.event ) === 'undefined' ||
            data.app_fields.event === null ) {
          console.error( 'No event name' );
          return;
        }
        if( typeof( data.app_fields.connect_source ) === 'undefined' ||
            data.app_fields.connect_source === null ) {
          console.error( 'No source' );
          return;
        }
        if( typeof( data.app_fields.connect_target ) === 'undefined' ||
            data.app_fields.connect_target === null ) {
          console.error( 'No target' );
          return;
        }
        if( typeof( data.app_fields.timestamp ) === 'undefined' ||
            data.app_fields.timestamp === null ) {
          console.error( 'No timestamp' );
          return;
        }

        var event_name = data.app_fields.event;
        var source = data.app_fields.connect_source;
        var target = data.app_fields.connect_target;
        var timestamp = data.app_fields.timestamp;
        var params = null;
        if( typeof( data.app_fields.event_params ) !== 'undefined' &&
            data.app_fields.event_params !== null ) {
          params = data.app_fields.event_params;
        }

        var tg = getTarget( source, target );
        if( typeof( eventsCb[ event_name ] ) !== 'undefined' &&
            eventsCb[ event_name ] !== null &&
            typeof( eventsCb[ event_name ][ tg ] ) !== 'undefined' &&
            typeof( eventsCb[ event_name ][ tg ] ) === 'function' ) {
          eventsCb[ event_name ][ tg ]( err, event_name, source, target, timestamp, params );
        }
      } );
    };

    return {
      register: register
    };
  } )();
  /************** END OF EVENTS *************************/

  /************** COMMANDS *************************/
  var commands = ( function() {
    var commandCb = null;
    var register = function( _cb ) {
      if( typeof( _cb ) !== 'function' ) {
        console.error( 'commands.register callback is not a function' );
        return;
      }
      commandCb = _cb;

      var _destination = {
        device_id: config_source.device_id,
        app_id: config_source.app_id
      };

      events.register( _destination, 'command',
        function( err, event_name, source, target, timestamp, event_params ) {
          if( err ) {
            commandCb( err );
            return;
          }
          if( typeof( event_params ) === 'undefined' || event_params === null ) {
            console.error( 'No command data' );
            return;
          }
          if( typeof( event_params.command_id ) === 'undefined' ||
            event_params.command_id === null ) {
            console.error( 'No command_id' );
            return;
          }
          if( typeof( event_params.command ) === 'undefined' ||
              event_params.command === null ) {
            console.error( 'No command' );
            return;
          }

          var command_id = event_params.command_id;
          var command_name = event_params.command;
          var command_params = null;

          if( typeof( event_params.command_params ) !== 'undefined' ||
              event_params.command_params !== null ) {
            command_params = event_params.command_params;
          }

          commandCb( err, command_name, command_id, source, target, timestamp, command_params );
        }
      );
    };

    var command_completed = function( _cmd_id, _cmd_status, _cb ) {
      var url = config_host + 'connect/commands';
      var params = {
        connect_source: config_source,
        command_id: _cmd_id,
        command_status: _cmd_status
      };
      var data = {
        id: 1,
        jsonrpc: '2.0',
        method: 'complete',
        params: params
      };

      config_ajax( url, data, 'POST', function ( _err, _res ) {
        _cb( _err, _res );
      } );
    };

    var get_commands = function( _cb ) {
      var url = config_host + 'connect/commands';
      var params = {
        connect_source: config_source
      };
      var data = {
        id: 1,
        jsonrpc: '2.0',
        method: 'list',
        params: params
      };

      config_ajax( url, data, 'POST', customCallback );
    };


    return {
      register: register,
      get_commands: get_commands,
      command_completed: command_completed
    };
  } )();
  /************** END OF COMMANDS *************************/

  return {
    "register_connect": connect.register,
    "register_events": events.register,
    "register_notifications": notifications.register,
    "ack_notifications": notifications.ack,
    "get_notifications": notifications.get,
    "register_commands": commands.register,
    "command_completed": commands.command_completed,
    "get_commands": commands.get_commands
  };
};
